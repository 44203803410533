<template>
  <nav class="navbar navbar-expand-lg navbar-light sticky-top bg-white">
    <div class="container-fluid">
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" id="btn">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <div class="navbar-nav">
          <router-link class="nav-link" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show"
                       @click="to('home')" :to="{name:'home'}">{{ FA_LAN.MENU.HOME }}
          </router-link>
          <router-link class="nav-link" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show"
                       @click="to('ticket')" :to="{name:'ticket'}">{{ FA_LAN.MENU.TICKETS }}
          </router-link>
          <router-link class="nav-link" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show"
                       @click="to('buy')" :to="{name:'buy'}">{{ FA_LAN.MENU.BUYS }}
          </router-link>
          <router-link class="nav-link" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show"
                       @click="to('category')" :to="{name:'category'}">{{ FA_LAN.MENU.MAIN_EDUCATIONS }}
          </router-link>
          <router-link class="nav-link" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show"
                       @click="to('package')" :to="{name:'package'}">{{ FA_LAN.MENU.PACKAGES }}
          </router-link>
          <router-link class="nav-link" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show"
                       @click="to('sms')" :to="{name:'sms'}">{{ FA_LAN.MENU.SMS }}
          </router-link>
          <router-link class="nav-link" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show"
                       @click="to('etc')" :to="{name:'etc'}">{{ FA_LAN.MENU.ETC }}
          </router-link>
          <a class=" nav-link text-danger" @click="logout" href="#">{{ FA_LAN.MENU.LOGOUT }}</a>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import persian from "../fa-IR/fa-ir";
import {useStore} from "vuex";
import {useRouter} from "vue-router";

export default {
  name: "Menu",
  setup() {
    const FA_LAN = persian;
    const store = useStore();
    const router = useRouter();

    async function logout() {
      await store.dispatch("apiKey/logout");
    }

    function to(rout) {
      router.push({name: rout})
    }

    return {FA_LAN, logout, to}
  }
}
</script>

<style scoped>
nav {
  font-family: "iransans";
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .2);
}

.nav-link {
  margin-left: 10px;
  border-radius: 5px;
}

.nav-link:hover {
  background-color: #f5f5f5;
}

.router-link-active {
  color: #39C0ED !important;
  border-bottom: 1px #39C0ED solid;
  border-radius: 0px;
}
</style>