const errors = {
    UNKNOWN_ERROR: "'خطایی رخ داده است'",
    RECHAPTCHA_NOT_VALID: 'کپچا تایید نشد',
    USERNAME_NOT_ENTER: "لطفا نام کاربری را وارد نمایید",
    PASSWORD_NOT_ENTER: "لطفا پسورد را وارد نمایید",
    PHONE_NOT_ENTER: "شماره همراهی وارد نشده است",
    DATA_NOT_EXIST: "داده ای وجود ندارد",

    DELETE_EDUCATION_CONFIRM: 'آیا مطمئنید آموزش فوق حذف شود؟',

    LOW_CHARGE_PRICE: 'مبلغ کمتر از 5هزار تومان است',

    TICKET_CLOSED: 'تیکت بسته شده است',
}

export default errors;