import {getPost} from "../../js/api/restAPI";
import {sweetAlert} from "../../js/alert/sweetAlert";

const ticket = {
    namespaced: true,
    state: {
        tickets: [],
    },
    getters: {
        get(state) {
            return state.tickets;
        },
        find: (state) => (ticketID) => {
            return state.tickets.find(t => t.id == ticketID)
        }
    },
    mutations: {
        setNew(state, data) {
            state.tickets = data;
        },
        add(state, data) {
            state.tickets.push(...data);
        }
    }
    ,
    actions: {
        async fetch({commit},{index}) {

            await getPost(`admin/ticket/getList?index=${index}`, null)
                .then(function (response) {
                    if (response.data.code !== 200) {
                        sweetAlert('error', response.data.description)

                    } else {
                        if (index === 0) {//new search or fetch
                            commit("setNew", response.data.data.tickets);
                        } else {
                            commit("add", response.data.data.tickets);
                        }
                    }
                }).catch(function (error) {
                    sweetAlert('error', error)
                })
        }
    }
}


export default ticket