<template>
  <div>
    <div class="d-flex col-md-8">
      <div class="ms-4">
        <router-link class="btn btn-success btn-sm pe-3 ps-3" :to="{name:'createEducation'}">
          {{ FA_LAN.EDUCATION_SUBMIT }}
        </router-link>
      </div>
      <div class="ms-4">
        <router-link class="btn btn-dark btn-sm pe-3 ps-3" :to="{name:'newCategory'}">
          {{ FA_LAN.CATEGORY_SUBMIT }}
        </router-link>
      </div>
    </div>
    <hr>
    <div class="d-flex justify-content-center mt-3">
      <p v-if="empty">{{ FA_LAN.NO_DATA }}</p>
      <div v-else class="card shadow-me p-3 table-responsive col-md-10">
        <table class="table table-hover table-striped">
          <thead>
          <tr>
            <th scope="col">{{ FA_LAN.EDUCATION_NAME }}</th>
            <th scope="col">{{ FA_LAN.EDUCATION_COUNT }}</th>
            <th scope="col">{{ FA_LAN.UPDATE_DATE }}</th>
            <th scope="col">{{ FA_LAN.CATEGORY_CODE }}</th>
            <th scope="col">{{ FA_LAN.CATEGORY_LOGO }}</th>
            <th scope="col"></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="mainCategory in categoryLists" :key="mainCategory.id">
            <td>
              <router-link class="text-black link"
                           :to="{name:'getEducationVideo',params:{mainCategory:mainCategory.code}}">
                {{ mainCategory.title }}
              </router-link>
            </td>
            <td>{{ mainCategory.count }}</td>
            <td>{{ tojalali(mainCategory.last) }}</td>
            <td>{{ mainCategory.code }}</td>
            <td><img class="text-black link" :src="mainCategory.logo"/></td>
            <td>
              <router-link class="btn btn-outline-dark btn-sm"
                           :to="{name:'getSubcategory',params:{mainCategory:mainCategory.code}}">
                {{ FA_LAN.SUB_CATEGORY }}
              </router-link>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import persian from "../../fa-IR/fa-ir";
import {computed, onMounted, ref, watch} from "vue";
import {gregorian_to_jalali} from "../../js/date/convert";
import {useStore} from "vuex";

export default {
  name: "Educations",
  setup() {
    const store = useStore()
    const categoryLists = computed(() => store.getters["mainCategory/get"]);
    const empty = ref(true);
    const FA_LAN = persian;

    onMounted(() => {
      checKEmptyObject();
    })

    watch(categoryLists, () => {
      checKEmptyObject();
    })

    function checKEmptyObject() {
      empty.value = categoryLists.value.length === 0;
    }

    function tojalali(miladi) {
      if (miladi) {
        return gregorian_to_jalali(miladi);
      }
    }

    return {FA_LAN, categoryLists, empty, tojalali}
  }
}
</script>

<style scoped>
img {
  width: 35px;
  height: auto;
}
</style>