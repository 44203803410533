const sweetAlert = {
    successTitle: 'آفرین',
    errorTitle: 'ای بابا',
    successText: 'عملیات با موفقیت انجام شد',
    bottomText: 'خب',


    //delete admin
    DELETE_ADMIN_TITLE:"ایا از حذف کاربر (username) مطمئن هستید؟",
    YES:"بله",
    NO:"انصراف",

    //push
    CONFIRM_SEND_PUSH:"ایا از ارسال پوش مطمئن هستید؟"
}

export default sweetAlert;