const forms = {
    //login form
    LOGIN_FORM_TITLE: 'ورود به مدیریت',
    USERNAME: 'نام کاربری',
    PASSWORD: 'پسورد',

    //chat form
    FILE: 'فایل',

    //buy form
    PHONE: 'شماره همراه',
    PLAN: 'پلن',
    PRICE: 'مبلغ (تومان)',
    DATE: 'تاریخ',
    MARKET: 'مارکت',
    GOOGLE_PLAY: 'گوگل پلی',
    CAFFE_BAZAAR: 'کافه بازار',
    TRACKING_CODE: 'کد پیگیری',
    BUY_FOR_OTHER: 'خرید برای دیگران',
    BUYER_PHONE: 'شماره همراه خریدار',

    //education
    LEVEL1: 'مقدماتی',
    LEVEL2: 'پیشرفته',
    FULL_LEVEL: "مقدماتی و پیشرفته",
    MAIN_CATEGORY: 'دسته اصلی',
    SUB_CATEGORY: 'دسته فرعی',
    EDUCATION_NUMBER: 'شماره آموزش',
    EDUCATION_TITLE: 'عنوان آموزش',
    TAGS: 'تگ ها',
    EXTRA_FILE: 'لینک فایل کمکی',
    MAIN_EDUCATION_LINK: 'لینک فایل اصلی',

    //main category
    CATEGORY_TITLE: 'دسته آموزشی',
    CATEGORY_LOGO: 'لوگو',
    CATEGORY_CODE: 'کد دسته',

    //packages
    PACKAGE_NAME:"نام پکیج",
    PACKAGE_SUBTITLE:"توضیحات کوتاه",
    PACKAGE_DETAIL:"توضیحات کامل",

    //sms charge
    WITH_TAX:"مبلغ نهایی: ",
    TOMAN:" تومان",


    //push
    PUSH_TITLE:"عنوان پوش",
    PUSH_MESSAGE:"متن پوش",

    //plan
    PACKAGE_PLAN:"قیمت برای پکیج",
    MAIN_CATEGORY_PLAN:"قیمت برای دوره های اصلی",
    SINGLE_PLAN:"برای خرید تک جلسه ای",
    GROUP_PLAN:"برای خرید پکیج",
}

export default forms;