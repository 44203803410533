<template>
  <div class="d-flex justify-content-center mt-lg-5 mb-5">
    <form class="col-md-8 card shadow-me p-3">
      <div class="mb-3">
        <div class="form-group">
          <label class="form-label">{{ FA_LAN.PACKAGE_NAME }}</label>
          <select class="form-control" v-model.lazy="education.package_id">
            <option v-for="packageItem in packages" :value="packageItem.id" :key="packageItem.id">
              {{ packageItem.title }}
            </option>
          </select>
        </div>
      </div>
      <div class="mb-3">
        <div class="form-group">
          <label class="form-label">{{ FA_LAN.CATEGORY_TITLE }}</label>
          <select class="form-control" v-model.lazy="education.main_category_id">
            <option v-for="mainCategory in mainCategories" :key="mainCategory.id" :value="mainCategory.id">
              {{ mainCategory.title }}
            </option>
          </select>
        </div>
      </div>
      <div class="d-grid gap-2 d-md-flex justify-content-md-end">
        <button type="submit" class="btn btn-dark btn-sm ps-5 pe-5" @click.prevent="submit" :disabled="formLoader">
          <div class="spinner-border spinner-border-sm" role="status" v-if="formLoader"></div>
          {{ FA_LAN.EDUCATION_SUBMIT }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import {computed, reactive, ref} from "vue";
import {getPost} from "../../../js/api/restAPI";
import {sweetAlert} from "../../../js/alert/sweetAlert";
import persian from "../../../fa-IR/fa-ir";
import {useStore} from "vuex";

export default {
  name: "New",
  setup() {
    const education = reactive({
      package_id: "",
      main_category_id: "",
    })
    const mainCategory = ref('');
    const search = ref('');
    const store = useStore();
    const packages = computed(() => store.getters["packages/get"]);
    const mainCategories = computed(() => store.getters["mainCategory/get"]);
    const formLoader = ref(false);
    const moreLoader = ref(false);
    const FA_LAN = persian;


    async function submit() {
      formLoader.value = true

      await getPost('admin/education/package/new', education)
          .then(function (response) {
            if (response.data.code !== 200) {
              sweetAlert('error', response.data.description);
            } else {
              sweetAlert('success');
            }

          }).catch(function (error) {
            sweetAlert('error', error);
            console.log(error)
          })

      formLoader.value = false
    }

    return {
      education,
      packages,
      formLoader,
      FA_LAN,
      mainCategories,
      search,
      moreLoader,
      mainCategory,
      submit
    }
  }
}
</script>

<style scoped>

</style>