import {createStore} from 'vuex'
import apiKey from "./includes/apiKey";
import plan from "./includes/plan";
import mainCategory from "./includes/mainCategory";
import education from "./includes/education";
import packages from "./includes/packages";
import ticket from "./includes/ticket";
import subCategory from "./includes/subCategory";

const store = createStore({
    modules: {
        apiKey,
        plan,
        mainCategory,
        education,
        packages,
        ticket,
        subCategory
    }
})


export default store;