<template>
  <div>
    <div v-if="loading" class="modal-me">
      <div class="my-modal-content">
        <div class="spinner-border " role="status"></div>
      </div>
    </div>
    <div v-else ref='scrollComponent'>
      <div class="d-flex justify-content-center mt-lg-5 row">
        <form class="col-md-8">
          <div class="row">
            <div class="mb-3 col-6">
              <label class="form-label">{{ FA_LAN.PHONE }}</label>
              <input class="form-control" type="number" v-model.lazy="phone"/>
              <div class="form-text text-danger">{{ textError }}</div>
            </div>
            <div class="col-6 d-flex justify-content-sm-start align-items-center ">
              <button type="submit" class="btn btn-dark btn-sm pe-3 ps-3" @click.prevent="search"
                      :disabled="formLoader">
                <div class="spinner-border spinner-border-sm" role="status" v-if="formLoader">
                </div>
                {{ FA_LAN.RECEIVING_DATA }}
              </button>
            </div>
          </div>
        </form>
        <div class="col-md-3 d-flex justify-content-end align-items-end">
          <router-link class="btn btn-success btn-sm pe-3 ps-3" :to="{name:'newBuy'}">
            {{ FA_LAN.PAY_SUBMIT }}
          </router-link>
          <router-link class="btn btn-outline-secondary btn-sm pe-3 ps-3 me-2" :to="{name:'plans'}">
            {{ FA_LAN.PLANS }}
          </router-link>
        </div>
      </div>
      <hr>
      <div class="table-responsive">
        <table class="table table-striped table-hover">
          <thead class="thead-dark">
          <tr>
            <th scope="col">{{ FA_LAN.PRICE }}</th>
            <th scope="col">{{ FA_LAN.PLAN }}</th>
            <th scope="col">{{ FA_LAN.PHONE }}</th>
            <th scope="col">{{ FA_LAN.TRACKING_CODE }}</th>
            <th scope="col">{{ FA_LAN.MARKET }}</th>
            <th scope="col">{{ FA_LAN.DATE }}</th>
          </tr>
          </thead>
          <span v-if="empty">{{ FA_LAN.ERRORS.DATA_NOT_EXIST }}</span>
          <tbody v-else>
          <tr v-for="buy in buys" :key="buy.id">
            <td>{{ numberWithCommas(buy.amount) }}</td>
            <td>{{ getPlanOrEducation(buy) }}</td>
            <td>{{ buy.phone }}</td>
            <td>{{ buy.extra_information }}</td>
            <td>{{ buy.market }}</td>
            <td>{{ tojalali(buy.created_at) }}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="d-flex justify-content-center mt-3 mb-3" v-if="loadMoreButton">
        <!--        <button @click="loadMore" class="btn btn-outline-secondary btn-sm pe-3 ps-3" :disabled="moreLoader">-->
        <div class="spinner-border" role="status" v-if="moreLoader"></div>
        <!--          {{ FA_LAN.LOAD_MORE }}-->
        <!--        </button>-->
      </div>
    </div>
  </div>
</template>

<script>
import {onMounted, onUnmounted, ref} from "vue";
import {getPost} from "../../js/api/restAPI";
import {sweetAlert} from "../../js/alert/sweetAlert";
import {gregorian_to_jalali} from "../../js/date/convert";
import persian from "../../fa-IR/fa-ir";
import {planOrEducationName, thousandCommas} from "../../js/functions";

export default {
  name: "Search",
  setup() {
    const phone = ref('');
    const textError = ref('');
    const formLoader = ref(false)
    const loading = ref(true)
    const moreLoader = ref(false)
    const empty = ref(true);
    const loadMoreButton = ref(true);
    const buys = ref([]);
    const index = ref(0);
    const FA_LAN = persian;
    const scrollComponent = ref(null);

    async function search() {
      if (phone.value === '') {
        textError.value = FA_LAN.ERRORS.PHONE_NOT_ENTER;
        return;
      }

      textError.value = '';
      formLoader.value = true;

      await getPost('admin/buy/getUserBuyList', {phone: phone.value})
          .then(function (response) {
            if (response.data.code !== 200) {
              sweetAlert('error', response.data.description);
            } else {
              buys.value = response.data.data.buyLists;
              checKEmptyObject();
            }
          }).catch(function (error) {
            sweetAlert('error', error);
          })

      formLoader.value = false;
      loadMoreButton.value = false;
    }

    async function getBuyList() {
      await getPost(`admin/buy/getLastBuy?index=${index.value}`, null)
          .then(function (response) {
            if (response.data.code !== 200) {
              sweetAlert('error', response.data.description);
            } else {
              buys.value.push(...response.data.data.buyLists); //push for support add more

              checKEmptyObject();
            }

          }).catch(function (error) {
            sweetAlert('error', error);
          })

      loading.value = false;
      moreLoader.value = false;
    }

    onMounted(() => {
      getBuyList();

      window.addEventListener('scroll', loadMore);
    })

    onUnmounted(() => {
      window.removeEventListener('scroll', loadMore);
    })

    async function loadMore() {
      if (!checkPreviousReceive()) {
        return;
      }

      let element = scrollComponent.value
      if (element.getBoundingClientRect().bottom < window.innerHeight) {

        if (index.value - buys.value.length > 10) { //for stop looping
          return;
        }
        moreLoader.value = true;
        index.value += 10;
        await getBuyList();
      }
    }

    function tojalali(miladi) {
      return gregorian_to_jalali(miladi);
    }

    function checKEmptyObject() {
      empty.value = buys.value.length === 0;
    }

    function numberWithCommas(value) {
      return thousandCommas(value);
    }

    function getPlanOrEducation(plan) {
      return planOrEducationName(plan)
    }

    function checkPreviousReceive() {
      return buys.value.length === index.value + 10;
    }

    return {
      phone,
      textError,
      formLoader,
      empty,
      buys,
      FA_LAN,
      loading,
      moreLoader,
      loadMoreButton,
      scrollComponent,
      loadMore,
      tojalali,
      search,
      numberWithCommas,
      getPlanOrEducation
    }
  }
}
</script>

<style scoped>

</style>