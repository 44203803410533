<template>
  <div class="d-flex justify-content-center mt-lg-5 mb-5">
    <form class="col-md-8  card shadow-me p-3">
      <div class="form-check form-switch mb-3">
        <label class="form-check-label" for="forOther">{{ FA_LAN.BUY_FOR_OTHER }}</label>
        <input type="checkbox" class="form-check-input" id="forOther" v-model="buyForOther">
      </div>
      <div class="mb-3" v-if="buyForOther">
        <label class="form-label">{{ FA_LAN.BUYER_PHONE }}</label>
        <input class="form-control" type="number" v-model.lazy="pay.buyerPhone"/>
      </div>
      <div class="mb-3">
        <label class="form-label">{{ FA_LAN.PHONE }}</label>
        <input class="form-control" type="number" v-model.lazy="pay.phone"/>
      </div>
      <div class="mb-3">
        <div class="form-group">
          <label class="form-label">{{ FA_LAN.PLAN }}</label>
          <select class="form-control" v-model.lazy="pay.plan_id" @change="setPlanPrice">
            <option v-for="plan in plansData" :key="plan.id" :value="plan.id">{{ plan.main_category_title }} -
              {{ plan.sub_category_title }}
            </option>
          </select>
        </div>
      </div>
      <div class="mb-3">
        <label class="form-label">{{ FA_LAN.PRICE }}</label>
        <input class="form-control" type="text" v-model.lazy="pay.amount"/>
      </div>
      <div class="mb-3">
        <label class="form-label">{{ FA_LAN.DATE }}</label>
        <input class="form-control" type="text" v-model.lazy="shamsiDate"/>
      </div>
      <div class="mb-3">
        <div class="form-group">
          <label>{{ FA_LAN.MARKET }}</label>
          <select class="form-control" v-model.lazy="pay.market">
            <option value="google">{{ FA_LAN.GOOGLE_PLAY }}</option>
            <option value="bazaar">{{ FA_LAN.CAFFE_BAZAAR }}</option>
          </select>
        </div>
      </div>
      <div class="mb-3">
        <label class="form-label">{{ FA_LAN.TRACKING_CODE }}</label>
        <input class="form-control" type="text" v-model.lazy="refIdOrToken"/>
      </div>
      <div class="d-grid gap-2 d-md-flex justify-content-md-end">
        <button type="submit" class="btn btn-dark btn-sm ps-5 pe-5" @click.prevent="submit" :disabled="formLoader">
          <div class="spinner-border spinner-border-sm" role="status" v-if="formLoader"></div>
          {{ FA_LAN.PAY_SUBMIT }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import {computed, reactive, ref, watch} from "vue";
import {jalali_to_gregorian} from "../../js/date/convert";
import {getPost,} from "../../js/api/restAPI";
import {sweetAlert} from "../../js/alert/sweetAlert";
import persian from "../../fa-IR/fa-ir";
import {useStore} from "vuex";

export default {
  name: "New",
  setup() {
    const pay = reactive({
      plan_id: '',
      amount: '',
      date: '',
      market: '',
      refID: '',
      token: '',
      phone: '',
      buyerPhone: '',
    })
    const FA_LAN = persian;

    const refIdOrToken = ref('');
    const shamsiDate = ref('');
    const store = useStore();
    const plansData = computed(() => store.getters["plan/get"]);
    const formLoader = ref(false);
    const buyForOther = ref(false);

    function toMiladi() {
      pay.date = jalali_to_gregorian(shamsiDate.value);
    }

    function setRefIdOrToken() {
      if (pay.market === 'google') {
        pay.refID = refIdOrToken.value

      } else if (pay.market === 'bazaar') {
        pay.token = refIdOrToken.value
      }
    }

    watch(buyForOther, () => {
      //last check for empty buy for others
      if (buyForOther.value === false) {
        pay.buyerPhone = '';
      }

      pay.amount = '';
      pay.plan_id = '';
    })

    async function submit() {
      formLoader.value = true;

      setRefIdOrToken();
      toMiladi();

      await getPost('admin/buy/saveBuyInformation', pay)
          .then(function (response) {
            if (response.data.code !== 200) {
              sweetAlert('error', response.data.description);

            } else {
              sweetAlert('success');
            }

          })
          .catch(function (error) {
            sweetAlert('error', error);
          })

      formLoader.value = false
    }

    function setPlanPrice(event) {
      const planID = event.target.value;
      const plan = store.getters["plan/find"](planID)
      if (buyForOther.value) {
        pay.amount = plan.amount * .7;
      } else {
        pay.amount = plan.amount;
      }

    }

    return {pay, formLoader, refIdOrToken, shamsiDate, plansData, FA_LAN, buyForOther, submit, setPlanPrice};
  }

}
</script>

<style scoped>
.form-check .form-check-input {
  float: right !important;
  margin-right: 1.5em !important;
  margin-left: 0.5em !important;
}
</style>