export function saveToLocal(key, value) {
    if (typeof value !== 'number' && typeof value !== 'string') {
        value = JSON.stringify(value);
    }
    localStorage.setItem(key, value);
}

export function getFromLocal(key) {
    let val = localStorage.getItem(key);
    try {
        val = JSON.parse(val);
    } catch (e) {
        //
    }
    return val;
}