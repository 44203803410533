import Swal from "sweetalert2";
import FA_LAN from "../../fa-IR/fa-ir";

export function sweetAlert(type, error) {
    if (type === 'success') {
        return Swal.fire({
            // title: Fa_LAN.sweetAlert.successTitle,
            text: FA_LAN.sweetAlert.successText,
            icon: 'success',
            position: "top",
            showConfirmButton: false,
            toast: true,
            timer: 3000,
            // confirmButtonText: Fa_LAN.sweetAlert.bottomText
        })
    } else {
        return Swal.fire({
            // title: Fa_LAN.sweetAlert.errorTitle,
            text: error,
            icon: 'error',
            position: "top",
            showConfirmButton: false,
            toast: true,
            timer: 3000,
            // confirmButtonText: Fa_LAN.sweetAlert.bottomText
        })
    }
}

export function sweetAlertBTN(title) {
    return Swal.fire({
        text: title,
        icon: 'warning',
        position: "top",
        showCancelButton: true,
        confirmButtonText: FA_LAN.sweetAlert.YES,
        cancelButtonText: FA_LAN.sweetAlert.NO,
        toast: true
    })
}