<template>
  <div class="d-flex justify-content-center mt-lg-5">
    <div class="card  mb-3 col-md-5 shadow-me">
      <div class="card-header bg-info p-4 text-center text-white "><h5 class="mb-0">{{ FA_LAN.LOGIN_FORM_TITLE }}</h5>
      </div>
      <div class="card-body">
        <form>
          <div class="mb-3">
            <label class="form-label">{{ FA_LAN.USERNAME }}</label>
            <input type="text" class="form-control" v-model.lazy="formData.username">
            <div class="form-text text-danger">{{ error.usernameError }}</div>
          </div>
          <div class="mb-3">
            <label class="form-label">{{ FA_LAN.PASSWORD }}</label>
            <input type="password" class="form-control" v-model.lazy="formData.password">
            <div class="form-text text-danger">{{ error.passwordError }}</div>
          </div>
          <!--          <div class="alert alert-danger" role="alert" v-if="errorLogin!==''">{{ errorLogin }}</div>-->
          <div class="d-grid gap-2 d-md-flex justify-content-md-end">
            <button type="submit" class="btn btn-dark ps-5 pe-5" @click.prevent="login" :disabled="loading">
              <div class="spinner-border spinner-border-sm" role="status" v-if="loading"></div>
              {{ FA_LAN.LOGIN }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import {reactive, ref} from "vue";
import persian from "../fa-IR/fa-ir";
import {recaptchChek} from "../js/api/restAPI";
import {sweetAlert} from "../js/alert/sweetAlert";
import {useReCaptcha} from "vue-recaptcha-v3";
import {useStore} from "vuex";

export default {
  name: "Login",

  setup() {
    const formData = reactive({
      username: '',
      password: '',
    })
    const error = reactive({
      usernameError: '',
      passwordError: '',
    })
    const {executeRecaptcha, recaptchaLoaded} = useReCaptcha()
    const FA_LAN = persian;
    const tokenValid = ref(false);
    const loading = ref(false);
    const store = useStore();

    function validate() {
      if (formData.username === '') {
        error.usernameError = FA_LAN.ERRORS.USERNAME_NOT_ENTER
        return false
      }

      if (formData.password === '') {
        error.passwordError = FA_LAN.ERRORS.PASSWORD_NOT_ENTER
        return false
      }

      return true
    }

    async function login() {
      if (!validate()) {
        return;
      }

      loading.value = true;

      await recaptcha();

      if (!tokenValid.value) {
        loading.value = false;
        return;
      }

      await store.dispatch('apiKey/login', {username: formData.username, password: formData.password})

      loading.value = false;
    }

    const recaptcha = async () => {
      // (optional) Wait until recaptcha has been loaded.
      await recaptchaLoaded()

      // Execute reCAPTCHA with action "login".
      const token = await executeRecaptcha('login')

      // Do stuff with the received token.
      await recaptchChek(token).then(function (response) {

        if (response.data.success === true && response.data.score >= .5) {
          tokenValid.value = true;

        } else {
          sweetAlert('error', FA_LAN.ERRORS.RECHAPTCHA_NOT_VALID);
        }
      }).catch(function (error) {
        sweetAlert('error', error);
      })
    }


    return {formData, FA_LAN, error, loading, login}
  }
}
</script>

<style scoped>

.bg-info {
  background-color: #39C0ED !important;
}

.card-header:first-child {
  border-radius: 0px 15px 0px 0px !important;
}
</style>