<template>
  <div class="d-flex justify-content-center mt-lg-5 mb-5">
    <form class="col-md-8 card shadow-me p-3">
      <div class="mb-3">
        <div class="form-group">
          <label class="form-label">{{ FA_LAN.MAIN_CATEGORY }}</label>
          <select class="form-control" v-model.lazy="education.main_category">
            <option v-for="mainCategory in mainCategories" :value="mainCategory.code" :key="mainCategory.id">
              {{ mainCategory.title }}
            </option>
          </select>
        </div>
      </div>
      <div class="mb-3">
        <div class="form-group">
          <label class="form-label">{{ FA_LAN.SUB_CATEGORY }}</label>
          <select class="form-control" v-model.lazy="education.sub_category">
            <option v-for="subCategory in subCategories" :key="subCategory.id" :value="subCategory.code">
              {{ subCategory.title }}
            </option>
          </select>
          <div class="spinner-border spinner-border-sm" role="status" v-if="subLoader"></div>
        </div>
      </div>
      <div class="mb-3">
        <label class="form-label">{{ FA_LAN.EDUCATION_NUMBER }}</label>
        <input class="form-control" type="number" v-model.lazy="education.education_number"/>
      </div>
      <div class="mb-3">
        <label class="form-label">{{ FA_LAN.EDUCATION_TITLE }}</label>
        <input class="form-control" type="text" v-model.lazy="education.title"/>
      </div>
      <div class="mb-3">
        <label class="form-label">{{ FA_LAN.TAGS }}</label>
        <input class="form-control" type="text" v-model.lazy="education.tags"/>
      </div>
      <div class="mb-3">
        <label class="form-label">{{ FA_LAN.EXTRA_FILE }}</label>
        <input class="form-control" type="text" v-model.lazy="education.extra_url"/>
      </div>
      <div class="mb-3">
        <label class="form-label">{{ FA_LAN.MAIN_EDUCATION_LINK }}</label>
        <input class="form-control" type="text" v-model.lazy="education.url"/>
      </div>
      <!--      <div class="form-text text-danger">{{ textError }}</div>-->
      <div class="d-grid gap-2 d-md-flex justify-content-md-end">
        <button type="submit" class="btn btn-dark btn-sm ps-5 pe-5" @click.prevent="submit" :disabled="formLoader">
          <div class="spinner-border spinner-border-sm" role="status" v-if="formLoader"></div>
          {{ FA_LAN.EDUCATION_SUBMIT }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import {computed, reactive, ref, watch} from "vue";
import {getPost} from "../../../js/api/restAPI";
import {sweetAlert} from "../../../js/alert/sweetAlert";
import persian from "../../../fa-IR/fa-ir";
import {useStore} from "vuex";

export default {
  name: "New",
  setup() {
    const education = reactive({
      main_category: "",
      sub_category: "",
      education_number: "",
      title: "",
      tags: "",
      extra_url: "",
      url: ""
    })
    const store = useStore();
    const mainCategories = computed(() => store.getters["mainCategory/get"]);
    const formLoader = ref(false);
    const FA_LAN = persian;
    const subCategories = computed(() => store.getters["subCategory/filter"])
    const subLoader = ref(false);
    const oldMainCategory = ref('');

    watch(education, async () => {
      if (education.main_category !== oldMainCategory.value) {//for not run in every field change
        subLoader.value = true;
        await store.dispatch("subCategory/fetch", {mainCategory: education.main_category});
        subLoader.value = false;

        oldMainCategory.value = education.main_category;
      }
    })

    async function submit() {
      formLoader.value = true

      await getPost('admin/education/main/new', education)
          .then(function (response) {
            if (response.data.code !== 200) {
              sweetAlert('error', response.data.description);
            } else {
              sweetAlert('success');
              store.dispatch('mainCategory/fetch');
            }

          }).catch(function (error) {
            sweetAlert('error', error);
          })

      formLoader.value = false
    }

    return {education, mainCategories, formLoader, FA_LAN, subCategories, subLoader, submit}
  }
}
</script>

<style scoped>

</style>