<template>
  <div v-if="loading" class="modal-me">
    <div class="my-modal-content">
      <div class="spinner-border " role="status"></div>
    </div>
  </div>
  <div v-else ref='scrollComponent'>
    <div class="d-flex col-md-8">
      <div class="ms-4">
        <router-link class="btn btn-dark btn-sm pe-3 ps-3" :to="{name:'ticketCategories'}">
          {{ FA_LAN.TICKET_CATEGORIES }}
        </router-link>
      </div>
    </div>
    <hr>
    <div class="d-flex justify-content-center">
      <p v-if="empty">{{ FA_LAN.NO_DATA }}</p>
      <div v-else class="card shadow-me table-responsive col-md-10 p-4 m-2">
        <table class="table table-hover">
          <thead>
          <tr>
            <th></th>
            <th>{{ FA_LAN.TICKET_TITLE }}</th>
            <th>{{ FA_LAN.TICKET_CATEGORY }}</th>
            <th>{{ FA_LAN.TICKET_CREATOR }}</th>
            <th>{{ FA_LAN.TICKET_NUMBER }}</th>
            <th>{{ FA_LAN.CREATED_AT }}</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="ticket in ticketList" :key="ticket.id" @click="goToChat(ticket.id)" :class="closed(ticket)">
            <td><span class="badge bg-danger" v-if="ticket.unread!==0">{{ ticket.unread }}</span></td>
            <td>{{ ticket.title }}</td>
            <td>{{ ticket.category_title }}</td>
            <td>{{ ticket.phone }}</td>
            <td>{{ ticket.ticket_number }}</td>
            <td> {{ tojalali(ticket.created_at) }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="d-flex justify-content-center mt-3 mb-3">
      <!--      <button @click="loadMore" class="btn btn-outline-secondary btn-sm pe-3 ps-3" :disabled="moreLoader">-->
      <div class="spinner-border" role="status" v-if="moreLoader"></div>
      <!--        {{ FA_LAN.LOAD_MORE }}-->
      <!--      </button>-->
    </div>
  </div>
</template>

<script>
import {computed, onMounted, onUnmounted, ref, watch} from "vue";
import {gregorian_to_jalali} from "../../js/date/convert";
import persian from "../../fa-IR/fa-ir";
import {useRouter} from "vue-router";
import {useStore} from "vuex";

export default {
  name: "Tickets",
  setup() {
    const loading = ref(true);
    const moreLoader = ref(false);
    const empty = ref(true);
    const ticketList = computed(() => store.getters["ticket/get"]);
    const index = ref(0);
    const FA_LAN = persian;
    const router = useRouter();
    const scrollComponent = ref(null);
    const store = useStore();

    async function getTicketList() {
      await store.dispatch("ticket/fetch", {index: index.value})
      loading.value = false;
      moreLoader.value = false;
    }

    onMounted(() => {
      getTicketList();

      window.addEventListener('scroll', loadMore);
    })

    onUnmounted(() => {
      window.removeEventListener('scroll', loadMore);
    })

    watch(ticketList, () => {
      checKEmptyObject();
    })

    async function loadMore() {
      if (!checkPreviousReceive()) {
        return;
      }

      let element = scrollComponent.value
      if (element.getBoundingClientRect().bottom < window.innerHeight) {

        if (index.value - ticketList.value.length > 10) { //for stop looping
          return;
        }

        moreLoader.value = true;
        index.value += 10;
        getTicketList();
      }
    }

    function tojalali(miladi) {
      return gregorian_to_jalali(miladi);
    }

    function checKEmptyObject() {
      empty.value = ticketList.value.length === 0;
    }

    function goToChat(ticketID) {
      router.push({name: 'chats', params: {id: ticketID}})
    }

    function closed(ticket) {
      if (ticket.close) {
        return "bg-light";
      }
    }

    function checkPreviousReceive() {
      return ticketList.value.length === index.value + 10;
    }

    return {
      ticketList,
      loading,
      empty,
      FA_LAN,
      moreLoader,
      scrollComponent,
      tojalali,
      checKEmptyObject,
      loadMore,
      goToChat,
      closed
    }
  }
}
</script>

<style scoped>
small {
  font-size: 75%;
}
</style>