const menu = {
    HOME: 'صفحه اصلی',
    TICKETS: 'تیکت ها',
    MAIN_EDUCATIONS: 'آموزش ها',
    BUYS: 'خرید ها',
    PACKAGES: 'پکیج ها',
    SMS: 'پیامک ها',
    ETC: 'سایر',
    LOGOUT: "خروج"
};

export default menu;