const tables = {
    NO_DATA: 'نتیجه ای یافت نشد',

    //report
    ALL_USER_COUNT: 'تعداد کل کاربران',
    BUY_USER_COUNT: 'تعداد کل خریداران',
    BUY_MARKET_AMOUNT: 'خرید از مارکت ها',
    BUY_KIND: 'نوع',
    BUY_COUNT: 'تعداد',
    //filter
    BEGIN_DATE: 'تاریخ شروع',
    END_DATE: 'تاریخ پایان',

    //main category
    EDUCATION_NAME: 'نام آموزش',
    EDUCATION_COUNT: 'تعداد آموزش',
    UPDATE_DATE: 'تاریخ بروزرسانی',
    CREATED_AT: 'تاریخ ایجاد',

    //all education video
    DOWNLOAD_FILE: 'دانلود فایل',

    //tickets
    TICKET_TITLE: 'عنوان',
    TICKET_CATEGORY: 'دسته',
    TICKET_NUMBER: 'شماره تیکت',
    TICKET_CREATOR: 'ایجاد کننده',
    TICKET_DESCRIPTION:"توضیحات",
    TICKET_AMOUNT:"هزینه ثبت تیکت",
    TICKET_MIN_AMOUNT:"حداقل هزینه خدمات",

    //sms
    CREDIT:"شارژ باقیمانده: ",
    RIAL:" ریال",
    CHARGE_LOG:"تاریخچه شارژ پنل",
    DATE:"تاریخ",
    AMOUNT:"مبلغ (ریال)",
    REFID:"کد پیگیری",
    TEXT:"پیامک",
    OPERATOR:"اپراتور",

    //show plan
    MAIN_AMOUNT:"قیمت اصلی",
    OFF_AMOUNT:"قیمت با تخفیف",
    BAZAAR_CODE:"کد بازار",
    SINGLE_PLANS:"پلن های خرید تک جلسه ای",

    //user table
    NAME:"نام و نام خانوادگی",
    STATE:"استان",
    ACQUAINTED:"طریقه آشنایی",
}

export default tables;