import {saveToLocal, getFromLocal} from '../localStorage';
import {getPost} from "../../js/api/restAPI";
import {sweetAlert} from "../../js/alert/sweetAlert";

const apiKey = {
    namespaced: true,
    state: {
        apiKey: getFromLocal('key') ? getFromLocal('key') : '',
    },
    getters: {
        get(state) {
            return state.apiKey;
        },
        status(state) {
            return state.apiKey !== '' && state.apiKey !== null;
        }
    },
    mutations: {
        set(state, key) {
            state.apiKey = key;
            saveToLocal('key', state.apiKey);
        },
        remove(state) {
            state.apiKey = '';
            saveToLocal('key', state.apiKey);
        }
    },
    actions: {
        async login({commit}, {username, password}) {

            await getPost('admin/login', {
                username: username,
                password: password,
            }).then(function (response) {
                if (response.data.code !== 200) {
                    sweetAlert('error', response.data.description)

                } else {
                    commit("set", response.data.data.apiKey);

                }
            }).catch(function (error) {
                sweetAlert('error', error)
            })
        },
        async logout({commit}) {
            await getPost('admin/logout', null)
                .then(function (response) {
                    if (response.data.code !== 200) {
                        sweetAlert('error', response.data.description)
                    } else {
                        commit("remove");
                    }
                }).catch(function (error) {
                    sweetAlert('error', error)
                })
        },
        async checkLogin({commit, getters}) {
            if (getters.get !== '' || getters.get !== null) {
                await getPost('checkLogin', null)
                    .then(function (response) {
                        if (response.data.code !== 200) {
                            commit("remove");
                        }
                    }).catch(function (error) {
                        sweetAlert('error', error)
                    })
            }
        }
    }
}


export default apiKey