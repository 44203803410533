import axios from "axios";
import store from '../../store/index';

// const baseUrl = 'http://localhost:8000/v1/'
const baseUrl = 'https://app.elnazbeauty.ir/api/v1/'
const smsBaseUrl = 'https://sms.negahgroup.ir/API/v1/'

export async function getPost(url, data, file = false) {
    const apiKey = getApiKey();

    var header = '';
    if (file === true) {
        header = {
            'Content-Type': 'multipart/form-data',
            'api-key': apiKey
        }
    } else {
        header = {'api-key': apiKey}
    }

    return await axios.post(baseUrl + url, data, {headers: header});
}

export async function recaptchChek(token) {
    var url = "https://app.elnazbeauty.ir/googlePlay/recaptcha.php";
    var data = {token: token}
    return await axios.post(url, data);
}

export async function smsPost(url, data, smsKey) {
    const header = {'api-key': smsKey}

    return await axios.post(smsBaseUrl + url, data, {headers: header});
}

function getApiKey() {
    return store.getters["apiKey/get"];
}

