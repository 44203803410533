const buttons = {
    //form
    LOGIN: 'ورود',
    DELETE: 'حذف',
    FILTER: 'فیلتر',
    SEARCH: 'جست و جو',
    LOAD_MORE: 'موارد بیشتر',
    SEND_RESPONSE: 'ارسال پاسخ',
    PAY_SUBMIT: 'ثبت پرداخت جدید',
    RECEIVING_DATA: 'دریافت اطلاعات',
    CLOSE_TICKET: 'بستن',

    //separated
    EDUCATION_SUBMIT: 'ثبت آموزش جدید',
    CATEGORY_SUBMIT: 'ثبت دسته جدید',
    PACKAGE_SUBMIT: 'ثبت پکیج جدید',
    PACKAGE_MORE_DESCRIPTION: 'توضیحات',

    //sms
    CHARGE:"شارژ پنل",

    //plan
    PLANS: "پلن ها",
    EDIT: "ویرایش",
    SAVE: "ذخیره",
    NEW_PLAN: "پلن جدید",
    NEW_PLAN_SUBMIT: "ثبت پلن جدید",

    //ticket
    TICKET_CATEGORIES:"دسته ها",
    NEW_TICKET_CATEGORIES:"ایجاد دسته جدید",

    //new ADmin
    ADD_ADMIN:"افزودن کاربر ادمین",

    //push
    SEND_PUSH:"ارسال پوش"

}

export default buttons;