<template>
  <div class="d-flex justify-content-center mt-lg-5 mb-5">
    <form class="col-md-8  card shadow-me p-3">
      <div class="mb-3">
        <label class="form-label">{{ FA_LAN.CATEGORY_TITLE }}</label>
        <input class="form-control" type="text" v-model.lazy="category.title"/>
      </div>
      <div class="mb-3">
        <label class="form-label">{{ FA_LAN.CATEGORY_CODE }}</label>
        <input class="form-control" type="text" v-model.lazy="category.code"/>
      </div>
      <div class="mb-3">
        <label class="form-label">{{ FA_LAN.CATEGORY_LOGO }}</label>
        <input class="form-control" type="text" v-model.lazy="category.logo"/>
      </div>

      <div class="d-grid gap-2 d-md-flex justify-content-md-end">
        <button type="submit" class="btn btn-dark btn-sm ps-5 pe-5" @click.prevent="submit" :disabled="formLoader">
          <div class="spinner-border spinner-border-sm" role="status" v-if="formLoader"></div>
          {{ FA_LAN.CATEGORY_SUBMIT }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import {reactive, ref} from "vue";
import persian from "../../fa-IR/fa-ir";
import {getPost} from "../../js/api/restAPI";
import {sweetAlert} from "../../js/alert/sweetAlert";
import {useRouter} from "vue-router";
import {useStore} from "vuex";

export default {
  name: "New",
  setup() {
    const category = reactive({
      title: '',
      code: '',
      logo: ''
    })
    const FA_LAN = persian;
    const formLoader = ref(false);
    const router = useRouter();
    const store = useStore();

    async function submit() {
      formLoader.value = true;

      await getPost('admin/category/createMain', category)
          .then(function (response) {
            if (response.data.code !== 200) {
              sweetAlert('error', response.data.description);

            } else {
              sweetAlert('success');
              store.dispatch('mainCategory/fetch');
              router.push({name: 'category'});
            }
          })
          .catch(function (error) {
            sweetAlert('error', error);
          })

      formLoader.value = false

    }

    return {FA_LAN, category, formLoader, submit}
  }
}
</script>

<style scoped>

</style>