import {createApp} from 'vue'

import App from './App.vue'

import router from "./router";

import {VueReCaptcha} from 'vue-recaptcha-v3'

import store from "./store";

import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'bootstrap/dist/css/bootstrap.min.css';

import "vue3-persian-datepicker/dist/datepicker.min.css";

const app = createApp(App);
app.use(router);
app.use(router);
app.use(store);
app.use(VueReCaptcha, {siteKey: '6Lew2jMcAAAAAGhIGs_1BAVlzgR9SuH_xBcVHX2T'})
app.mount('#app')
